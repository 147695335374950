

































type RouteItem = {
  routeName: string;
  label: string;
};

export default {
  name: 'SuperAdminOperatorView',
  data(): { childRoutes: RouteItem[] } {
    return {
      childRoutes: [
        {
          routeName: 'game-provider-copy-stake-stats',
          label: 'CopyStake stats'
        },
        {
          routeName: 'game-provider-reporting-api',
          label: 'Reporting API'
        },
        {
          routeName: 'game-provider-settings',
          label: 'Settings'
        }
      ]
    };
  }
};
